import React from "react";
import {useTranslation} from "react-i18next";
import Modal from "../../../../../components/modal/Modal";
import ModalTitle from "../../../../../components/modal/ModalTitle";
import "./projectExportErrorsModal.scss";
import ModalButtons from "../../../../../components/modal/ModalButtons";
import Button from "../../../../../components/ui/Button";
import {useSyncMaterialsContext} from "../providers/SyncMaterialsDataProvider";

interface ProjectExportErrorsModalProps {
	onClose: () => void
}

const ProjectExportErrorsModal: React.FC<ProjectExportErrorsModalProps> = ({onClose}) => {

	const {result: {costCenterExportStatuses}} = useSyncMaterialsContext()
	const {t} = useTranslation();

	function renderErrorList() {
		const errors: { id: string, message: string }[] = []

		costCenterExportStatuses.forEach(((value, index) => {
			if (value.errors.length > 0) {
				errors.push(...value.errors.map(
					error => ({
						id: `${value.costCenter.id}_${index}`,
						message: `${value.costCenter.name} - ${error}`
					})
				))
			}
		}))

		return errors.map(error => (
			<li key={error.id}>{error.message}</li>
		))
	}

	return (
		<Modal isOpen={true} onClose={onClose}>
			<ModalTitle>{t("projects.summary.exportErrorsModal.title")}</ModalTitle>
			<span className="text-default">{t("projects.summary.exportErrorsModal.errorMsg")}</span>
			<span className="project-export-errors-modal_contact-msg">{t("projects.summary.exportErrorsModal.contactMsg")}</span>
			<span className="text-default">{t("projects.summary.exportErrorsModal.listHeader")}</span>
			{costCenterExportStatuses.length > 0 ? (
				<ul className="project-export-errors-modal_error-list">
					{renderErrorList()}
				</ul>
			) : null}

			<ModalButtons>
				<Button
					label={t("projects.summary.exportErrorsModal.closeButtonLabel")}
					variant={"optional"}
					onClick={onClose}
				/>
			</ModalButtons>
		</Modal>
	);
};

export default ProjectExportErrorsModal;
