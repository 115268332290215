import React, {useState} from "react";
import "./projectTree.scss";
import Button from "../../../../components/ui/Button";
import TreeSections from "./TreeSections";
import {useTranslation} from "react-i18next";
import {ConversionSourceType, DrawingType, ProjectTreeModal, Status} from "../../../../models/enums";
import CreateDrawingModal from "./modals/CreateDrawingModal";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";
import {DownloadDrawingsDropdown} from "./DownloadDrawingsDropdown";
import {useDrawingExporter} from "../../features/drawing-exporter";
import DropdownItem from "../../../../components/ui/DropdownItem";
import {ExportMode} from "../../../project-drawings/features/editor/models/enums";
import CommonDropdown from "../../../../components/ui/CommonDropdown";
import {CreateSectionModal} from "./CreateSectionModal";
import Spinner from "../../../../components/Spinner";
import DataFetchError from "../../../../components/ui/DataFetchError";
import {CreateCostCenterModal} from "./CreateCostCenterModal";
import {ZipGenerator} from "../../../../utils/ZipUtils";
import {saveAs} from "file-saver";
import LoggerService from "../../../../services/LoggerService";
import NotificationService from "../../../../services/NotificationService";
import {SendSelectedAttachmentsButton} from "./SendSelectedAttachmentsButton";
import {useUserSettingsDataContext} from "../../../../providers/UserSettingsProvider";
import {useDrawingSummaryPDF} from "../../features/sync-materials/components/pdf/useDrawingSummaryPDF";
import {removeNotAllowedCharsFromFilename} from "../../../../utils/TextUtils";
import Section from "../../../../models/Section";
import {CostCenter} from "../../../../models/CostCenter";
import {CostCenterDrawing} from "../../../../models/CostCenterDrawing";


type Props = ReturnType<typeof useProjectDetailContext>["treeData"]
const Tree: React.FC<Props> = (props) => {

	const {sections, treeExpandData, selectionData} = props
	const {isAllTakeoffsSelected, selectAllTakeoffs, selection, canDownload, reset: resetSelection} = selectionData
	const {generateFilesFromSelection} = useDrawingExporter();
	const [modal, setModal] = useState<ProjectTreeModal>(ProjectTreeModal.NONE);
	const [createModalsData, setCreateModalsData] = useState<{ costCenterId?: string, sectionId?: string }>({})
	const {
		forceTreeUpdate,
		projectDetails,
		isServiceProject,
		fitTimesData
	} = useProjectDetailContext()
	const treeLoadStatus = props.loadStatus
	const projectLoadStatus = projectDetails.loadStatus
	const [downloadStatus, setDownloadStatus] = useState(Status.IDLE);
	const {settings} = useUserSettingsDataContext()
	const {createSelectedSummaryAttachment} = useDrawingSummaryPDF()
	const {fitTimes} = fitTimesData
	const {t} = useTranslation();

	async function downloadAll(exportMode: ExportMode) {
		try {
			setDownloadStatus(Status.LOADING)
			const downloadKeyTable = settings ? settings.downloadKeyTable && exportMode === ExportMode.PDF : false
			// To add a summary table, the export type will need to be changed to PNG
			// in order for the getDrawingFileData function to get the width and height.
			const exportModeType = downloadKeyTable ? ExportMode.PNG : exportMode

			let files = await generateFilesFromSelection({fileType: exportModeType}, selection)
			if (downloadKeyTable) {
				let incSummaryFiles: File[] = []
				for (const file of files) {
					const index = files.indexOf(file);
					let section = props.sections.find(section => section.id === selection[index].selectedSectionId);
					let costCenter = section?.costCenters.find(cc => cc.id === selection[index].selectedCostCenterId);
					let drawing = costCenter?.drawings.find(drawing => drawing.id === selection[index].selectedDrawingId);
					if (section && costCenter && drawing) {
						let incSummaryFile = await createSelectedSummaryAttachment(
							file,
							section,
							costCenter,
							drawing,
							selection[index].selectedVersionId,
							selection[index].drawingType,
							fitTimes
						)
						const newFileName = file.name.replace(/\.png/g, ".pdf");
						const renamedFile = new File([incSummaryFile], removeNotAllowedCharsFromFilename(newFileName), {type: incSummaryFile.type});
						incSummaryFiles.push(renamedFile)
					}
				}
				files = incSummaryFiles
			}
			const zipGenerator = new ZipGenerator()
			for (let file of files) {
				zipGenerator.addImage(file, file.name)
			}
			const zip = await zipGenerator.generateZip()
			saveAs(zip, t(`projects.details.drawingsZipDefaultName`))

			resetSelection()
			setDownloadStatus(Status.SUCCESS)
		}
		catch (e) {
			setDownloadStatus(Status.ERROR)
			LoggerService.logError(e);
			NotificationService.errorNotification(t("common.error"), t("common.buttons.downloadAs.downloadAsErrorDesc"));
		}
	}

	const onCreateBlankDrawingSuccess = () => {
		closeModal();
		forceTreeUpdate();
	};

	const openCreateBlankDrawingModal = (costCenterId?: string, sectionId?: string) => {
		setCreateModalsData({costCenterId, sectionId})
		setModal(ProjectTreeModal.CREATE_BLANK_DRAWING);
	}

	const openCreateSectionModal = () => setModal(ProjectTreeModal.CREATE_SECTION);
	const openCreateCostCenterModal = (sectionId?: string) => {
		setCreateModalsData({sectionId})
		setModal(ProjectTreeModal.CREATE_COST_CENTER);
	}
	const closeModal = () => setModal(ProjectTreeModal.NONE);

	const toggleAllTakeoffsChecked = () => {
		selectAllTakeoffs()
	}

	const renderNewButtonDropdown = () => {
		const button = (
			<Button
				label={t("projects.details.createNewButton.label")}
				withPlusIcon
				dropdownArrow
				variant={"optional"}
			/>
		)

		return (
			<CommonDropdown button={button}>
				<DropdownItem
					label={t("projects.details.createNewButton.dropdown.costCenter")}
					onClick={openCreateCostCenterModal}
				/>
				{!isServiceProject && (
					<DropdownItem
						label={t("projects.details.createNewButton.dropdown.section")}
						onClick={openCreateSectionModal}
					/>
				)}
			</CommonDropdown>
		)
	}

	return (
		<>
			{modal === ProjectTreeModal.CREATE_BLANK_DRAWING ? (
				<CreateDrawingModal
					initialData={createModalsData}
					onSuccess={onCreateBlankDrawingSuccess}
					drawingType={DrawingType.TAKE_OFF}
					onClose={closeModal}/>
			) : null}
			{modal === ProjectTreeModal.CREATE_SECTION ? (
				<CreateSectionModal onClose={closeModal}/>
			) : null}
			{modal === ProjectTreeModal.CREATE_COST_CENTER ? (
				<CreateCostCenterModal initialSectionId={createModalsData.sectionId} sections={sections} onClose={closeModal}/>
			) : null}
			<div className={"project-tree"}>
				{projectLoadStatus === Status.LOADING ? <Spinner/> : null}
				{projectLoadStatus === Status.ERROR ? <DataFetchError/> : null}
				{projectLoadStatus === Status.SUCCESS ? (
					<>
						{treeLoadStatus === Status.LOADING ? <Spinner/> : null}
						{treeLoadStatus === Status.ERROR ? <DataFetchError/> : null}
						{treeLoadStatus === Status.SUCCESS ? (
							<>
								<div className={"project-tree_header"}>
									<div>
										<label>
											<input type={"checkbox"} checked={isAllTakeoffsSelected()}
												   onChange={toggleAllTakeoffsChecked}/>
											<span className={"project-tree_header_title"}>
												{t("projects.details.tree.title.take_off")}
											</span>
										</label>
									</div>
									<div style={{flex: "1 1 0%"}}/>
									<div className={"project-tree_button-container"}>
										<DownloadDrawingsDropdown
											downloadStatus={downloadStatus}
											disabled={!canDownload}
										>
											<DropdownItem label={t("common.buttons.downloadAs.png")}
														  onClick={() => downloadAll(ExportMode.PNG)}/>
											<DropdownItem label={t("common.buttons.downloadAs.jpg")}
														  onClick={() => downloadAll(ExportMode.JPG)}/>
											<DropdownItem label={t("common.buttons.downloadAs.pdf")}
														  onClick={() => downloadAll(ExportMode.PDF)}/>
										</DownloadDrawingsDropdown>
										{projectDetails.project?.conversionSourceType === ConversionSourceType.Quote ? (
											<SendSelectedAttachmentsButton selection={selection}/>
										) : null}
										{renderNewButtonDropdown()}
										<Button label={t("projects.details.createBlankDrawingButton")}
												withPlusIcon
												onClick={() => {openCreateBlankDrawingModal()}}
												variant={"secondary"}/>
									</div>
								</div>
								<TreeSections
									openCreateBlankDrawingModal={openCreateBlankDrawingModal}
									sections={sections}
									onCreateNewSection={openCreateSectionModal}
									onCreateCostCenter={openCreateCostCenterModal}
									treeExpandData={treeExpandData}
								/>
							</>
						) : null}
					</>
				) : null}
			</div>
		</>
	);
};

export {Tree}

