import {CostCenterExportStatuses, CostCenterExportStatusItem, StatusType, SyncMaterialsModalData} from "../types";
import {useSyncStageModals} from "./useSyncStageModals";
import {useSyncMaterialsTreeSelectionData} from "./useSyncMaterialsTreeSelectionData";
import React, {useState} from "react";
import {Status} from "../../../../../models/enums";
import {useDrawingVersionsSummaryExporter} from "./useDrawingVersionsSummaryExporter";
import {useMeasurementsExporter} from "./useMeasurementsExporter";
import {CostCenter} from "../../../../../models/CostCenter";
import FitTime from "../../../../../models/FitTime";
import NotificationService from "../../../../../services/NotificationService";
import LoggerService from "../../../../../services/LoggerService";
import {useTranslation} from "react-i18next";
import {ExportStatus, SummaryExportStatus} from "../../../../../models/SummariesExportStatusResult";
import {OnFailure} from "../../../../../utils/CallbackUtils";


function toCostCenterExportStatuses(costCenters: CostCenter[], statuses: SummaryExportStatus[]): CostCenterExportStatuses {
	type CostCenterWithTasks = Map<string, {
		costCenter: CostCenter,
		taskMap: Map<string, SummaryExportStatus>
	}>

	const costCenterWithTasks = statuses.reduce<CostCenterWithTasks>(
		(accumulator, currentValue) => {
			const currentValueCostCenter = costCenters.find(
				scc => scc.containsDrawingVersion(currentValue.drawingVersionId)
			)
			if (!currentValueCostCenter) {
				return accumulator
			}

			const accumulatorCC = accumulator.get(currentValueCostCenter.id)
			if (accumulatorCC) {
				accumulatorCC.taskMap.set(currentValue.drawingVersionId, currentValue)
			}
			else {
				accumulator.set(currentValueCostCenter.id, {
					costCenter: currentValueCostCenter,
					taskMap: new Map([[currentValue.drawingVersionId, currentValue]])
				})
			}
			return accumulator;
		}, new Map()
	)

	return Array.from(costCenterWithTasks.entries()).map(([, {costCenter, taskMap}]) => {

		const taskArray = Array.from(taskMap.entries())

		const getExportStatus = function(): ExportStatus {
			if (taskArray.every(([, ses]) => ses.status === ExportStatus.PENDING)) {
				return ExportStatus.PENDING
			}
			if (taskArray.every(([, ses]) => ses.status === ExportStatus.COMPLETE)) {
				return ExportStatus.COMPLETE
			}
			if (taskArray.some(([, ses]) => ses.status === ExportStatus.FAIL)) {
				return ExportStatus.FAIL
			}
			return ExportStatus.PROGRESS
		}

		const getProgress = function() {
			if (taskArray.length === 0) return 0

			const completed = taskArray.filter(([, ses]) => ses.status === ExportStatus.COMPLETE).length
			const total = taskArray.length

			return Math.trunc((completed * 100) / total)
		}

		return {
			costCenter: costCenter,
			exportStatus: getExportStatus(),
			exportMeasurementStatus: ExportStatus.COMPLETE,
			exportSummaryStatus: getExportStatus(),
			progress: getProgress(),
			errors: [],
			statusType: StatusType.SUMMARY
		}
	})
}

function useSyncMaterialsData({takeoffSections, fitTimes}: SyncMaterialsModalData) {
	const syncStageModals = useSyncStageModals()
	const selectionState = useSyncMaterialsTreeSelectionData(takeoffSections)
	const drawingVersionsSummaryExporter = useDrawingVersionsSummaryExporter()
	const measurementExporter = useMeasurementsExporter()
	const {t} = useTranslation();
	const [selectedCostCenters, setSelectedCostCenters] = useState<CostCenter[]>([]);

	function clearResult() {
		setSelectedCostCenters([])
		drawingVersionsSummaryExporter.clearResult()
		measurementExporter.clearResult()
	}

	const result: {
		status: Status,
		costCenterExportStatuses: CostCenterExportStatuses
	} = React.useMemo(() => {
		const summaryStatus = drawingVersionsSummaryExporter.status
		const measurementStatus = measurementExporter.status

		const summaryResult = toCostCenterExportStatuses(
			selectedCostCenters,
			drawingVersionsSummaryExporter.result
		)
		const measurementResult = measurementExporter.result

		function getStatus(): Status {
			if (summaryStatus === Status.IDLE && measurementStatus === Status.IDLE) {
				return Status.IDLE
			}
			else if (summaryStatus === Status.SUCCESS && measurementStatus === Status.SUCCESS) {
				return Status.SUCCESS
			}
			else if (summaryStatus === Status.ERROR || measurementStatus === Status.ERROR) {
				return Status.ERROR
			}
			else {
				return Status.LOADING
			}
		}

		function mergeResult(): CostCenterExportStatuses {

			type MapType = Map<string, CostCenterExportStatusItem[]>

			const ccMap = [...summaryResult, ...measurementResult].reduce<MapType>(
				(accumulator, currentValue) => {
					const costCenterId = currentValue.costCenter.id
					const items = accumulator.get(costCenterId)
					if (!items) {
						accumulator.set(costCenterId, [currentValue])
					}
					else {
						items.push(currentValue)
					}
					return accumulator
				}, new Map()
			)

			const entries = Array.from(ccMap.entries())

			return entries.flatMap(([, items]) => {
				if (items.length === 0) {
					return []
				}

				function itemsSummaryStatus(): ExportStatus {
					const newItems = items.filter(item => item.statusType === StatusType.SUMMARY)
					if (newItems.length > 0) {
						if ( newItems.every(item => item.exportSummaryStatus === ExportStatus.PENDING)) {
							return ExportStatus.PENDING
						}
						if (newItems.every(item => item.exportSummaryStatus === ExportStatus.COMPLETE)) {
							return ExportStatus.COMPLETE
						}
						if (newItems.some(item => item.exportSummaryStatus === ExportStatus.FAIL)) {
							return ExportStatus.FAIL
						}
					}
					return ExportStatus.PROGRESS
				}

				function itemsMeasurementStatus(): ExportStatus {
					const newItems = items.filter(item => item.statusType === StatusType.MEASUREMENT)
					if (newItems.length > 0) {
						if (newItems.every(item => item.exportMeasurementStatus === ExportStatus.PENDING)) {
							return ExportStatus.PENDING
						}
						if (newItems.every(item => item.exportMeasurementStatus === ExportStatus.COMPLETE)) {
							return ExportStatus.COMPLETE
						}
						if (newItems.some(item => item.exportMeasurementStatus === ExportStatus.FAIL)) {
							return ExportStatus.FAIL
						}
					}
					return ExportStatus.PROGRESS
				}

				const progresSum = items.reduce((prevValue, currentValue) => (
					prevValue + currentValue.progress
				), 0)

				return {
					costCenter: items[0].costCenter,
					exportMeasurementStatus: itemsMeasurementStatus(),
					exportSummaryStatus: itemsSummaryStatus(),
					progress: progresSum / items.length,
					errors: items.flatMap(item => item.errors),
					statusType: StatusType.RESULT
				}
			})
		}

		return {
			status: getStatus(),
			costCenterExportStatuses: mergeResult()
		}
	}, [
		selectedCostCenters,
		measurementExporter.status, drawingVersionsSummaryExporter.status,
		measurementExporter.result, drawingVersionsSummaryExporter.result
	])

	function syncMaterials(projectId: string, costCenters: CostCenter[], fitTimes: FitTime[]) {
		setSelectedCostCenters(costCenters)
		const drawingData = costCenters.flatMap(cc => (
			cc.getAllLatestCompletedDrawingVersions()
		))

		const summaryExportAbortController = new AbortController()
		const measurementExportAbortController = new AbortController()

		const exportSummary = function() {
			return new Promise<void>((resolve, reject) => {
				const onFailure: OnFailure = error => {
					measurementExportAbortController.abort()
					reject(error)
				}
				drawingVersionsSummaryExporter.exportDrawingVersionsSummary(
					projectId, drawingData, fitTimes, takeoffSections, resolve, onFailure, summaryExportAbortController.signal
				)
			})
		}
		const exportMeasurements = function() {
			return new Promise<void>((resolve, reject) => {
				const onFailure: OnFailure = error => {
					summaryExportAbortController.abort()
					reject(error)
				}
				measurementExporter.exportMeasurements(
					projectId, costCenters, resolve, onFailure, measurementExportAbortController.signal
				)
			})
		}

		Promise.all([exportSummary(), exportMeasurements()]).then(() => {
			NotificationService.successNotification(t("common.success"), t("projects.summary.exportSuccessDesc"));
		}).catch(handleExportError)

		function handleExportError(error?: any) {
			let errorMsg;
			if (error?.response?.data?.exception?.includes("CostCentersCurrentlyExportedException")) {
				const {costCentersIds} = error?.response?.data;
				const costCenterNames: string = costCentersIds.map((ccId: string) => {
					const costCenter = costCenters.find(cc => cc.id === ccId);
					return costCenter?.name ?? "";
				}).join(", ");
				errorMsg = `${t("projects.summary.exportCCCurrentlyExportedErrorDesc")} ${costCenterNames}`;
			}
			else if (error?.response?.data?.exception?.includes("CostCentersWithNoCompletedDrawingsException")) {
				syncStageModals.closeModal();
				errorMsg = t("projects.summary.exportNoDrawingsErrorDesc");
			}
			else if (error?.response?.data?.exception?.includes("IllegalStateException")) {
				errorMsg = error?.response?.data?.message ? t("projects.summary.exportErrorDesc") +
					error?.response?.data?.message : t("projects.summary.exportIllegalStateErrorDesc");
			}
			else {
				errorMsg = t("projects.summary.exportErrorDesc");
			}
			NotificationService.errorNotification(t("common.error"), errorMsg);
			LoggerService.logError(error);
		}
	}

	return {
		takeoffSections,
		fitTimes,
		syncStageModals,
		selectionState,
		syncMaterials,
		clearResult,
		result,
	}
}

export {useSyncMaterialsData}